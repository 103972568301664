<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="分组"  >
                <a-select placeholder="请选择分组"  :options="groupList"  v-model="queryParam.groupId" style="width: 100%" allow-clear>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="房主ID"  >
                <a-input v-model="queryParam.userNo" placeholder="请输入房主ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="邀请日期" >
                <a-date-picker v-model="queryParam.startTime"    placeholder="请选择邀请日期" @change="onChangeDate1" allow-clear/> 至
                <a-date-picker v-model="queryParam.endTime"  placeholder="请选择邀请日期" @change="onChangeDate2" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="10" :sm="24">
              <a-form-item label="充值和消费日期"   >
                <a-date-picker v-model="queryParam.rechargeStartTime"    placeholder="请选择充值和消费日期" @change="onChangeDate3" allow-clear/> 至
                <a-date-picker v-model="queryParam.rechargeEndTime"  placeholder="请选择充值和消费日期" @change="onChangeDate4" allow-clear/>
              </a-form-item>
            </a-col>
            <!--            <a-col :md="5" :sm="24">-->
            <!--              <a-form-item label="用户昵称" >-->
            <!--                <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->

            <a-col :md="5" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'left', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.setGroupForm.handleAdd()" v-hasPermi="['biz:guild:add']">-->
<!--          <a-icon type="plus" />添加推广员-->
<!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:guild:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->

        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:guild:remove']">-->
        <!--          <a-icon type="delete" />删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:guild:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
      <set-group-form
        ref="setGroupForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :scroll="{ x: 1500, y: 750 }"
        :pagination="false"
        :bordered="tableBordered"
      >
            <span slot="avatar" slot-scope="text, record">
            <img style="width: 40px;height: 40px;margin-top: 5px;margin-right: 5px;" v-if="record.avatar"
                 v-for="(guildLogo,imgIndex) in record.avatar.split(',')"
                 :src="guildLogo"
                 preview="图片" />
        </span>

        <span slot="userIdentity" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_user_identity']" :value="record.userIdentity"/>
        </span>
        <span slot="position" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_user_position']" :value="record.position"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getRoomDataStatistics  } from '@/api/biz/guildStaff'
import { getGroupList  } from '@/api/biz/guildUser'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import SetGroupForm from '@/views/biz/guildUser/modules/SetGroupForm.vue'

export default {
  name: 'RoomDataStatistics',
  components: {
    SetGroupForm,
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['guild_user_identity', 'guild_user_position'],
  data () {
    return {
      list: [],
      groupList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        guildId: null,
        userId: null,
        userIdentity: null,
        position: null,
        startTime:null,
        endTime:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '房间名称',
          dataIndex: 'roomName',
          ellipsis: true,width: 150,
          align: 'center'
        },
        {
          title: '分组名称',
          dataIndex: 'groupName',
          ellipsis: true,width: 100,
          align: 'center'
        },
        {
          title: '房主ID',
          dataIndex: 'userNo',
          ellipsis: true,width: 100,
          align: 'center'
        },
        {
          title: '房主昵称',
          dataIndex: 'nickname',
          ellipsis: true,width: 100,
          align: 'center'
        },
        // {
        //   title: '邀请码',
        //   dataIndex: 'invitationCode',
        //   ellipsis: true,width: 100,
        //   align: 'center'
        // },
        // {
        //   title: '状态',
        //   dataIndex: 'jobStatus',
        //   ellipsis: true,
        //   align: 'center',width: 80,
        //   customRender: function (value) {
        //     if (value==1){
        //       return "在职"
        //     }else if(value==0){
        //       return "离职"
        //     }else {
        //       return "其他"
        //     }
        //   }
        // },
        {
          title: '邀请用户数量',
          dataIndex: 'inviteNum',
          ellipsis: true,
          align: 'center',width: 120,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        },
        {
          title: '邀请充值用户数量',
          dataIndex: 'rechargeCount',
          ellipsis: true,
          align: 'center',width: 160,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        },
        {
          title: '邀请充值金额',
          dataIndex: 'rechargeMoney',
          ellipsis: true,width: 160,
          align: 'center',
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        },
        {
          title: '邀请当日充值用户数量',
          dataIndex: 'todayMoneyCount',
          ellipsis: true,width: 160,
          align: 'center',
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        },
        {
          title: '邀请当日充值金额总计',
          dataIndex: 'todayRechargeMoney',
          ellipsis: true,
          align: 'center',width: 160,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        }
        ,
        {
          title: '邀请当日消费用户数量',
          dataIndex: 'todayConsumeCount',
          ellipsis: true,
          align: 'center',width: 160,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        }
        ,{
          title: '邀请当日消费金额',
          dataIndex: 'todayConsumeMoney',
          ellipsis: true,
          align: 'center',width: 160,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        }
        ,{
          title: '总消费用户数量',
          dataIndex: 'allConsumeCount',
          ellipsis: true,
          align: 'center',width: 160,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        }
        ,{
          title: '消费总流水',
          dataIndex: 'allStatements',
          ellipsis: true,
          align: 'center',width: 160,
          customRender: function (value) {
            if (value==null){
              return 0
            }else {
              return value
            }
          }
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.initGroupList()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    //时间选择
    onChangeDate1(value, dateString) {
      // this.queryParam.startTime = dateString;
      console.log(' Formatted Selected Time: ', dateString)
      this.queryParam.startTime = dateString;

    },
    onChangeDate2(value, dateString) {
      this.queryParam.endTime = dateString;
    },
    onChangeDate3(value, dateString) {
      this.queryParam.rechargeStartTime = dateString;
    },
    onChangeDate4(value, dateString) {
      this.queryParam.rechargeEndTime = dateString;
    },
    initGroupList(){
      getGroupList({}).then(response => {
        for(let i=0;i<response.data.length;i++){
          let params = {
            value:response.data[i].id,
            label:response.data[i].groupName
          }
          this.groupList.push(params);
        }
      });
    },

    /** 查询公会用户列表 */
    getList () {
      this.loading = true
      getRoomDataStatistics(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        guildId: undefined,
        userId: undefined,
        userIdentity: undefined,
        position: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      // this.selectedRowKeys = selectedRowKeys
      // this.selectedRows = selectedRows
      // this.ids = this.selectedRows.map(item => item.id)
      // this.single = selectedRowKeys.length !== 1
      // this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/guildUser/export', {
            ...that.queryParam
          }, `公会用户_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
